import "./App.css";
import Homepage from "./Homepage/Homepage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Invitations from "./Invitations/Invitations";
import Confirmation from "./Confirmation/Confirmation";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/invitations" element ={<Invitations />} />
          <Route path="/confirm" element ={<Confirmation />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
