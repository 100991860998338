import {
  FacebookOutlined,
  XOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Space } from "antd";
import "antd/dist/antd.css";
import "./Footer.css";

function Footer() {
  return (
    <div>
      <div className="desktop">
        <div className="row">
          <div className="column1">
            <div className="sponsorsFooter">
              <div className="containerFooter">
                <h1 className="heading">Sponzori i partneri / Sponsors & Partners</h1>
                <div className="group clearfix">
                  <p className="title">Pod podkroviteljstvom  / Under the patronage of</p>
                  <div className="table">
                    <div className="cell">
                      <a href="https://www.sff.ba/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2024/unesco4.svg" alt="Unesco Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix">
                  <p className="title">Glavni sponzori / Main Sponsors</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://www.coca-cola.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/cocacola2017.svg" alt="Coca Cola Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="http://bhtelecom.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/bhtelecom-footer2.svg" alt="BH Telecom Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.unicredit.ba/ba/stanovnistvo.html">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/unicredit-white1.svg" alt="Unicredit Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix">
                  <p className="title">Ekskluzivni sponzori / Exclusive Sponsors</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://www.atlantic.hr/hr/">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/atlantic.svg" alt="Atlantic Logo" />
                      </a>
                    </div>
                    <div className="cell">
                      <a href="http://mastercard.com">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/mastercard.svg" alt="Mastercard Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.uniqa.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2023/uniqa-1.svg" alt="Uniqa Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.bingotuzla.ba/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2023/bingo.svg" alt="Bingo Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix">
                  <p className="title">Podržan od / Supported by</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://ec.europa.eu/culture/index_en.htm">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/media2019-5.svg" alt="European Commission Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix">
                  <p className="title">Partneri festivala / Festival partners</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://www.dohafilminstitute.com/">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/doha-2-2.svg" alt="Doha Film Institute Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="http://www.trt.net.tr/bosanski/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/trt-22.svg" alt="TRT Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://cineplexx.ba/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2024/cineplexx2.svg" alt="Cineplexx Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix clear">
                  <p className="title">Pod podkroviteljstvom / Under patronage</p>
                  <div className="table">
                    <div className="cell">
                      <a href="https://www.fmks.gov.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/fbih1.svg" alt="FMKS Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://fondacijakinematografija.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/fond.svg" alt="Fondacija Kinematografije Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.fmoit.gov.ba/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2024/minioko3.svg" alt="FMOIT Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://vlada.ks.gov.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/ks.svg" alt="Vlada Kantona Sarajevo Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.sarajevo.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/gradsa2.svg" alt="Grad Sarajevo Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.visitsarajevo.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/visitsa.svg" alt="Visit Sarajevo Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="column2">
            <h1 className="heading">Ostale informacije / Other information</h1>
            <a href="https://www.sff.ba/">
              <h3 className="subheading5">Sarajevo Film Festival</h3>
            </a>
            <a href="https://tickets.sff.ba/films">
              <h3 className="subheading5">SFF Online tickets</h3>
            </a>
            <a href="https://ondemand.kinomeetingpoint.ba/" className="inline">
              <h3 className="subheading5">Ondemand platform </h3> <ExportOutlined style={{ display: 'inline', margin: '0 0 0 10px', padding: '5px 0 0 0', fontSize: 'large' }} className="icons" />
            </a>
            <div className="socialMedia">
              <Space size="large">
                <a href="https://www.facebook.com/sarajevofilmfestival" target="_blank" rel="noopener noreferrer">
                  <FacebookOutlined className="icons" />
                </a>
                <a href="https://x.com/30thSFF" target="_blank" rel="noopener noreferrer">
                  <XOutlined className="icons" />
                </a>
                <a href="https://www.instagram.com/sarajevofilmfestival/" target="_blank" rel="noopener noreferrer">
                  <InstagramOutlined className="icons" />
                </a>
                <a href="https://www.youtube.com/channel/UCkvqgo7AfpOQHIRjFSpvInA" target="_blank" rel="noopener noreferrer">
                  <YoutubeOutlined className="icons" />
                </a>
              </Space>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile">
        <div className="rowm">
          <div className="columnm1">
            <h1 className="heading">Ostale informacije / Other information</h1>
            <a href="https://www.sff.ba/">
              <h3 className="subheadingm5">Sarajevo Film Festival</h3>
            </a>
            <a href="https://tickets.sff.ba/films">
              <h3 className="subheadingm5">SFF Online tickets</h3>
            </a>
            <a href="https://ondemand.kinomeetingpoint.ba/" className="inline">
              <h3 className="subheadingm5">Ondemand platform </h3> <ExportOutlined style={{ display: 'inline', margin: '0 0 0 10px', padding: '5px 0 0 0', fontSize: 'large' }} className="icons" />
            </a>
            <div className="socialMediam">
              <Space size="large">
                <a href="https://www.facebook.com/sarajevofilmfestival" target="_blank" rel="noopener noreferrer">
                  <FacebookOutlined className="icons" />
                </a>
                <a href="https://x.com/30thSFF" target="_blank" rel="noopener noreferrer">
                  <XOutlined className="icons" />
                </a>
                <a href="https://www.instagram.com/sarajevofilmfestival/" target="_blank" rel="noopener noreferrer">
                  <InstagramOutlined className="icons" />
                </a>
                <a href="https://www.youtube.com/channel/UCkvqgo7AfpOQHIRjFSpvInA" target="_blank" rel="noopener noreferrer">
                  <YoutubeOutlined className="icons" />
                </a>
              </Space>
            </div>            
          </div>
        </div>
        <div className="rowm1">
          <div className="columnm2">
            <div className="sponsorsFooter">
              <div className="containerFooter">
                <h1 className="heading">Sponzori i partneri / ponsors & Partners</h1>
                <div className="group clearfix">
                  <p className="title">Pod podkroviteljstvom / Under the patronage of</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://www.sff.ba/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2024/unesco4.svg" alt="Unesco Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix">
                  <p className="title">Glavni sponzori / Main Sponsors</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://www.coca-cola.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/cocacola2017.svg" alt="Coca Cola Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="http://bhtelecom.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/bhtelecom-footer2.svg" alt="BH Telecom Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.unicredit.ba/ba/stanovnistvo.html">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/unicredit-white1.svg" alt="Unicredit Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix">
                  <p className="title">Ekskluzivni sponzori / Exclusive Sponsors</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://www.atlantic.hr/hr/">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/atlantic.svg" alt="Atlantic Logo" />
                      </a>
                    </div>
                    <div className="cell">
                      <a href="http://mastercard.com">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/mastercard.svg" alt="Mastercard Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.uniqa.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2023/uniqa-1.svg" alt="Uniqa Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.bingotuzla.ba/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2023/bingo.svg" alt="Bingo Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix clear">
                  <p className="title">Podržan od / Supported by</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://ec.europa.eu/culture/index_en.htm">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/media2019-5.svg" alt="European Commission Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix">
                  <p className="title">Partneri festivala / Festival partners</p>
                  <div className="table">
                    <div className="cell">
                      <a href="http://www.dohafilminstitute.com/">
                        <img src="https://www.sff.ba/upload/images/sponzori/LOGOI2015/home/doha-2-2.svg" alt="Doha Film Institute Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="http://www.trt.net.tr/bosanski/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/trt-22.svg" alt="TRT Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://cineplexx.ba/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2024/cineplexx2.svg" alt="Cineplexx Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="group clearfix clear">
                  <p className="title">Pod podkroviteljstvom / Under patronage</p>
                  <div className="table">
                    <div className="cell">
                      <a href="https://www.fmks.gov.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/fbih1.svg" alt="FMKS Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://fondacijakinematografija.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/fond.svg" alt="Fondacija Kinematografije Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.fmoit.gov.ba/">
                        <img src="https://www.sff.ba/upload/images/sponzori/2024/minioko3.svg" alt="FMOIT Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://vlada.ks.gov.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/ks.svg" alt="Vlada Kantona Sarajevo Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.sarajevo.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/gradsa2.svg" alt="Grad Sarajevo Logo"/>
                      </a>
                    </div>
                    <div className="cell">
                      <a href="https://www.visitsarajevo.ba">
                        <img src="https://www.sff.ba/upload/images/sponzori/2022/visitsa.svg" alt="Visit Sarajevo Logo"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}

export default Footer;