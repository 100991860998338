import React, { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import Logo from "../Image/SFFLogo.svg";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import "antd/dist/antd.css";
import "./Homepage.css";
import Footer from "../Footer/Footer";

function Homepage() {
  const [pin, setPin] = useState("");
  const navigate = useNavigate();
  const [logInStatus, setLogInStatus] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [color, setColor] = useState("#ffbaba");

  let style = {
    fontFamily: "DIN 1451 Std",
    width: "70%",
    height: "100%",
    fontSize: "1.8vw",
  };

  useEffect(() => {
    const ActivateResponse = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const activateFunction = urlParams.get('confirmed');
      
      if (activateFunction === 'true') {
        setLogInStatus("Thank you for your response!");
        setColor("#bbffba");
        setIsShown(true);
      }
    };

    ActivateResponse();
  }, []);
    

  const login = async () => {
    const docRef = doc(db, "Users", pin);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const korisnik = docSnap.data();
      if (korisnik.Attendance === 'NA'){
        navigate("/confirm", {
          state: { username: korisnik.Name, PIN: pin, PlusOne: korisnik.PlusOne },
        });
      } else if (korisnik.Attendance === 'YES'){
      navigate("/invitations", {
        state: { username: korisnik.Name, PIN: pin},
      });
    } else {
      setLogInStatus("You have denied the invitation or the invitation confirmation deadline passed! Please contact the organizer if you think this is a mistake.");
      setColor("#ffbaba");
      setIsShown(true);
      console.log("Invitation denied or expired!");
    }
    } else {
      setLogInStatus("That is not a valid PIN!");
      setColor("#ffbaba");
      setIsShown(true);
      console.log("No such user!");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="my-auto" id="body">
      <img src={Logo} alt="Logo" className="img" />
      <h1 className="invite"> POZIVNICE / INVITATIONS </h1>
      <br></br>
      <form onSubmit={handleSubmit}>
        <>
          <Input
            placeholder="Unesite Vaš PIN broj / Enter your PIN"
            className="textinput"
            prefix={<UserOutlined className="site-form-item-icon" />}
            style={style}
            onChange={(e) => {
              setPin(e.target.value);
            }}
          />
          <br />
          <div className="errorContainer">
          <h3
            className="error-msg"
            style={{ display: isShown ? "block" : "none", backgroundColor : color}}
          >
            <i className="fa fa-times-circle"></i>
            &nbsp;
            {logInStatus}
          </h3>
          </div>
          

          <br />
        </>
        <button className="btn" onClick={login}>
          LOGIN
        </button>
      </form>
      <Footer />
    </div>
  );
}

export default Homepage;
