import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { store } from "../firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import "./Invitations.css";
import Footer from "../Footer/Footer";
import LogoHeader from "../Image/SFFLogoWide.svg";
import Cola from "../Image/cocacola.png";
import Bhtelecom from "../Image/bhtelecom.png";
import unicredit from "../Image/unicredit_header.png";

function Invitations() {
  let fileArray = useRef([]);
  let datum = new Date();
  var sati = datum.getHours();
  let greet = "Hello";

  if (sati < 12) greet = "Dobro jutro / Good Morning";
  else if (sati >= 12 && sati <= 17) greet = "Dobar dan / Good Afternoon";
  else if (sati >= 17 && sati <= 24) greet = "Dobro veče / Good Evening";

  const user = useLocation();
  const fullName = user.state.username;
  console.log(fullName);
  const refPIN = user.state.PIN;
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    const fileRef = ref(store, refPIN);
    listAll(fileRef).then(function (result) {
      result.items.forEach(function (filesRef) {
        const naziv = filesRef.name.replace('.pdf','');
        getDownloadURL(filesRef).then(function (fileurl) {
          const temp = { name: naziv, url: fileurl };
          fileArray.current = [...fileArray.current, temp];
          setFileList((fileList) => [...fileList, temp]);
        });
      });
    });
  }, [refPIN]);

  return (
    <div>
      <nav className="navbar">
<div className="logo"> <a href="/"><img src={LogoHeader} alt="Logo" className="img2" /></a></div>
  <div className="menu">
    <div className="inlineblock">
    <a href="http://www.coca-cola.ba/" target="blank_"><img src={Cola} alt="Logo" className="img3"  /></a></div>
    <div className="inlineblock"> <a href="http://bhtelecom.ba/" target="blank_"><img src={Bhtelecom} alt="Logo" className="img4" /></a></div>
    <div className="inlineblock"><a href="http://www.unicredit.ba/" target="blank_"><img src={unicredit} alt="Logo" className="img5" /></a></div>
   </div>
</nav>      

<hr/>
    <div className="Rules">
      <div className="content">
        <div id="Rules">
          <h2><b>
            {greet}, {fullName}
            </b>
          </h2>
          {fileList.length === 0 ? (
            <h3>Pozivnice će se početi unositi od 12. augusta 2024. <br />The invitations will be entered starting August 12th 2024, stay tuned for further updates.</h3>
            ) : (
              <h3>Ispod možete naći Vaše ulaznice: <br />Below you can find your invitations:</h3>
            )}
        </div>

        <div className="responsive-grid">
          {fileList.map((file, key) => {
            return (
              <a className="card1" key={key} href={file.url} target="_blank" rel="noopener noreferrer" aria-label={`${file.name} (opens in a new tab)`}>
                {file.name}
              </a>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  </div>
  );
}

export default Invitations;
