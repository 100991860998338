import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "./Confirmation.css";
import Footer from "../Footer/Footer";
import LogoHeader from "../Image/SFFLogoWide.svg";
import Cola from "../Image/cocacola.png";
import Bhtelecom from "../Image/bhtelecom.png";
import unicredit from "../Image/unicredit_header.png";


function Confirmation() {
    const user = useLocation();
    const fullName = user.state.username;
    const PIN = user.state.PIN;
    const userPlusOne = user.state.PlusOne;
    console.log(fullName, user.state.PlusOne);

    var [attendance, setAttendance] = useState('');
    var [plusOne, setPlusOne] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        updateDB();
    };

    useEffect(() => {
        if (attendance === 'NO') {
            setPlusOne('NO');
        }
    }, [attendance]);

    async function updateDB() {
        const docRef = doc(db, "Users", PIN);
        const docSnap = await getDoc(docRef);
        if (attendance.trim() === '') {
            console.error('Attendance must be entered.');
            return;
        }
        console.log('User state: ', user.state.plusOne, plusOne);
        if (userPlusOne === 'SHOW' && plusOne.trim() === '') {
            console.error('PlusOne must be entered.');
            return;
        }
        if (docSnap.exists()) {
            await updateDoc(docRef, {
                Attendance: attendance,
                PlusOne: plusOne ? plusOne : 'NO',
            }).then(() => {
                console.log("Form submission successful!");
                window.location.href = "/?confirmed=true";
            }).catch((error) => {
                console.error("Error submitting the form: ", error);
            });
        } else {
            console.log("No such document! This should not happen!");
        }
    }
    return (
        <div>
            <nav className="navbar">
                <div className="logo"> <a href="/"><img src={LogoHeader} alt="Logo" className="img2" /></a></div>
                <div className="menu">
                    <div className="inlineblock">
                        <a href="http://www.coca-cola.ba/" target="blank_"><img src={Cola} alt="Logo" className="img3" /></a></div>
                    <div className="inlineblock"> <a href="http://bhtelecom.ba/" target="blank_"><img src={Bhtelecom} alt="Logo" className="img4" /></a></div>
                    <div className="inlineblock"><a href="http://www.unicredit.ba/" target="blank_"><img src={unicredit} alt="Logo" className="img5" /></a></div>
                </div>
            </nav>

            <hr />
            <div className="Rules">
                <div className="content">
                    <div className="container">
                        <div className="pContainer">
                            <h1>Potvrda festivalske pozivnice (RSVP) / Festival invitation confirmation (RSVP)</h1>
                            <div style={{ marginBottom: "50px", marginTop: '40px' }}>
                            <p>Poštovani/a <b>{fullName}</b>, direktor Sarajevo Film Festivala, g. Jovan Marjanović, ima zadovoljstvo pozvati Vas na svečano otvaranje 30. Sarajevo Film Festivala 16. augusta 2024. godine.
 <br />Molimo Vas da potvrdite svoje prisustvo do <b>9. augusta 2024.</b> godine.
 Obavijestit ćemo Vas kada karte budu spremne putem elektronske pošte.</p>
 <p><b>Napominjemo da morate odgovoriti na sva pitanja. Imajte na umu da je vaš odgovor ovdje konačan i da se ne može promijeniti. Možete se odlučiti do isteka roka.</b></p>
                            </div>
                            <div style={{ marginBottom: "50px"}}>
                            <p>Dear <b>{fullName}</b>, the director of the Sarajevo Film Festival, Mr. Jovan Marjanović, is pleased to invite you to the opening ceremony of the 30th Sarajevo Film Festival on August 16th 2024.
                                <br />Kindly confirm your attendance by <b>August 9th 2024</b>.
                                You will receive an email when your tickets are ready so stay tuned for further updates.</p>
                            <p><b>Please note that you need to answer all questions. Please be aware that your answer here is final and cannot be changed. You can decide until the deadline.</b></p>
                        </div>
                        </div>
                        <div className="formContainer">
                            <form onSubmit={handleSubmit}>
                                <div className="formRow">
                                    <h3>Da li ste u mogućnosti prisustvovati? / Are you able to attend the event?</h3>
                                    <input
                                        type="radio"
                                        id="attendYes"
                                        name="attendance"
                                        value="yes"
                                        checked={attendance === 'YES'}
                                        onChange={() => setAttendance('YES')}
                                        className="radio-input"
                                    />
                                    <label htmlFor="attendYes" className="radio-label">Yes</label>

                                    <input
                                        type="radio"
                                        id="attendNo"
                                        name="attendance"
                                        value="no"
                                        checked={attendance === 'NO'}
                                        onChange={() => setAttendance('NO')}
                                        className="radio-input"
                                    />
                                    <label htmlFor="attendNo" className="radio-label">No</label>
                                </div>

                                {user.state.PlusOne !== 'HIDE' && (
                                    <div className="formRow">
                                        <h3>Da li prisustvujete sa partnerom ili partnericom? / Are you attending with a partner?</h3>
                                        <input
                                            type="radio"
                                            id="plusOneYes"
                                            name="plusOne"
                                            value="yes"
                                            checked={plusOne === 'YES'}
                                            disabled={attendance === 'NO'}
                                            onChange={() => setPlusOne('YES')}
                                            className="radio-input"
                                        />
                                        <label htmlFor="plusOneYes" className="radio-label">Yes</label>
                                
                                        <input
                                            type="radio"
                                            id="plusOneNo"
                                            name="plusOne"
                                            value="no"
                                            checked={plusOne === 'NO'}
                                            disabled={attendance === 'NO'}
                                            onChange={() => setPlusOne('NO')}
                                            className="radio-input"
                                        />
                                        <label htmlFor="plusOneNo" className="radio-label">No</label>
                                    </div>
                                )}

                                <button type="submit" className="btn">Submit</button>
                            </form>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Confirmation;
