// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD4L4Ws1w6sTQHVPpNcLG5BoYWJyaA3lTQ",
    authDomain: "file-sharing-app-64196.firebaseapp.com",
    projectId: "file-sharing-app-64196",
    storageBucket: "file-sharing-app-64196.appspot.com",
    messagingSenderId: "795618810547",
    appId: "1:795618810547:web:90c22438da84fc83f36ec6",
    measurementId: "G-40LW71QNVG"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const store = getStorage(app);